/**
 *
 * @param {int} amount
 * @param {string} formatString
 * @param {string} style
 * @param {string} currency
 * @param  {object} options
 * @returns format string
 */
import {format} from 'date-fns';

export function formatCurrency(amount, formatString, style, currency, ...options) {
  return new Intl.NumberFormat(formatString, {
    style,
    currency,
    ...options
  }).format(amount);
}

export function isoDateToYmd(isoDate) {
  return format(isoDate, 'yyyy-MM-dd');
}

export function isoDateToDmy(isoDate) {
  return format(isoDate, 'dd-MM-yyyy');
}

/**
 * Get a user-friendly error message based on the error object.
 *
 * @param {Object} error - The error object returned from an API call.
 * @param {Object} [error.data] - The response data containing additional error details.
 * @param {string} [error.data.message] - A general error message provided by the API.
 * @param {Object} [error.data.errors] - Detailed validation errors (used for status 422).
 * @param {number} error.status - The HTTP status code of the error.
 * @param {string} [error.statusText] - The HTTP status text associated with the error.
 * @returns {string} - A user-friendly error message.
 */
export const getErrorMessage = (error) => {
  if (!error || !error.status) {
    return "An unknown error occurred.";
  }

  switch (error.status) {
    case 400:
      return error.data?.message || "Bad Request: The server could not understand the request.";
    case 401:
      return error.data?.message || "Unauthorized: Access is denied due to invalid credentials.";
    case 403:
      return error.data?.message || "Forbidden: You don't have permission to access this resource.";
    case 404:
      return error.data?.message || "Not Found: The requested resource could not be found.";
    case 500:
      return error.data?.message || "Internal Server Error: Something went wrong on the server.";
    case 503:
      return error.data?.message || "Service Unavailable: The server is currently unavailable.";
    case 422:
      // Handle 422 validation errors specifically
      if (error.data?.errors) {
        return Object.entries(error.data.errors)
          .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
          .join("\n");
      }
      return error.data?.message || "Unprocessable Entity: Invalid input data.";
    default:
      return error.data?.message || `Error ${error.status}: ${error.statusText || "An unexpected error occurred."}`;
  }
};
