import React, { useEffect, useState } from "react";
import { Breadcrumb, Form } from "@themesberg/react-bootstrap";
import Invoice from "../libs/Invoice";
import InvoiceDetails from "./InvoiceDetails";
import InvoiceForm from "./InvoiceForm";
import InvoiceLayout from "./InvoiceLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { startLoading, stopLoading } from "../../store/actions/loading";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { editInvoiceSchemaNew } from "../../validation";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import StatusCard from "../../components/StatusCard";
import InvoiceReminder from "./InvoiceReminder";
import PriceCard from "../../components/PriceCard";
import { messageShown } from "../../store/reducers/message";
import Messages from "../../components/Messages";
import { useBooleanFlagValue } from "@openfeature/react-sdk";

function EditInvoice() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [contactPersonId, setContactPersonId] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [status, setStatus] = useState("open");
  const [showInvoiceLayout, setShowInvoiceLayout] = useState(false);
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [sendInvoiceEmail, setSendInvoiceEmail] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [firstReminder, setFirstReminder] = useState(null);
  const [secondReminder, setSecondReminder] = useState(null);
  const [defaultSetting, setDefaultSetting] = useState(true);
  const [disableReminderButton, setDisableReminderButton] = useState(false);
  const [invoiceDescription, setInvoiceDescription] = useState();
  const [customerId, setCustomerId] = useState();
  const [invoiceLines, setInvoiceLines] = useState([]);

  const remindersEnabled = useBooleanFlagValue('invoice-reminders', false);

  const handleCloseInvoiceLayout = () => setShowInvoiceLayout(false);
  const handleShowInvoiceLayout = () => setShowInvoiceLayout(true);


  useEffect(() => {
    if (defaultSetting) {
      setFirstReminder(null);
      setSecondReminder(null);
      setDisableReminderButton(true);
    } else {
      if (firstReminder) {
        setDefaultSetting(false)
        setDisableReminderButton(false);
      } else {
        setSecondReminder(null);
        setDisableReminderButton(true);
      }
    }
  }, [defaultSetting, firstReminder, secondReminder]);

  const updateInvoice = ({
    customer_id,
    contact_person_id,
    description,
    invoice_date,
    template_id,
    invoice_lines,
    email_to,
    status
  }) => {

    let reminderOverride = !defaultSetting && Boolean(firstReminder);

    const newInvoice = {
      id,
      customer_id,
      contact_person_id,
      invoice_description: description,
      template_id,
      invoice_date: moment
        .tz(invoice_date, "Europe/Amsterdam")
        .format("YYYY-MM-DD"),
      invoice_lines: invoice_lines.map((invoiceLine) => ({
        product_id: invoiceLine?.product?.id,
        description: invoiceLine?.product?.label,
        ...invoiceLine
      })),
      status,
      reminder_override: reminderOverride,
      first_reminder: !reminderOverride ? null : firstReminder,
      second_reminder: !reminderOverride ? null : secondReminder,
      email_to,
    };

    dispatch(startLoading()); // show loading
    Invoice.updateInvoice(newInvoice)
      .then((res) => {
        dispatch(stopLoading()); // hide loading
        window.location.href = "/invoices";
      })
      .catch((error) => {
        if (error.message) {
          dispatch(messageShown({ message: error.message, variant: "danger" }))
        }
        dispatch(stopLoading());
      });
  };

  useEffect(() => {
    fetchInvoiceSettings();
    //eslint-disable-next-line
  }, []);

  const fetchInvoiceSettings = () => {
    Invoice.getInvoiceSetting()
      .then((res) => {
        if (res.invoiceSettings) {
          setInvoicePrefix(res.invoiceSettings.invoice_prefix);
          setInvoiceNumber(
            res.invoiceSettings.next_invoice_number.padStart(5, "0")
          );
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    Invoice.getInvoiceSetting()
      .then((res) => {
        if (res.invoiceSetting) {
          setInvoicePrefix(res.invoiceSetting.invoice_prefix);
          setInvoiceNumber(
            res.invoiceSetting.next_invoice_number.padStart(5, "0")
          );
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    Invoice.getInvoice(id)
      .then((response) => {
        setCustomerId(response?.invoice?.customer_id);
        setContactPersonId(response?.invoice?.contact_person_id || null);
        if (response.invoice.invoice_date) {
          setInvoiceDate(parseISO(response.invoice.invoice_date));
        }

        // console.log('response?.invoice?.contact_person_id', response?.invoice?.contact_person_id)
        setTemplateId(response?.invoice?.template_id || null);
        setInvoiceDescription(response?.invoice?.invoice_description);
        setSendInvoiceEmail(response.invoice.email_to);

        setDefaultSetting(!response.invoice.reminder_override)
        setFirstReminder(response.invoice.reminder_override && response.invoice.first_reminder ? response.invoice.first_reminder : null);
        setSecondReminder(response.invoice.reminder_override && response.invoice.first_reminder ? response.invoice.second_reminder : null);

        const invoiceLines = response?.invoice?.invoice_lines || [];
        setInvoiceLines(invoiceLines.map(
          (invoiceLine) => ({
            amountExclVat_field: invoiceLine.price / 100,
            description: invoiceLine.description,
            product: {
              id: invoiceLine.product_id,
              label: invoiceLine.description,
              price: invoiceLine.product_id ? invoiceLine.price : null,
              vat: invoiceLine.product_id ? invoiceLine.vat_percentage : null
            },
            price: invoiceLine.price,
            quantity: invoiceLine.quantity,
            vat_percentage: invoiceLine.vat_percentage,
          })
        ));

        setStatus(response.invoice.status);
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line
  }, [id]);

  const handleDefaultSetting = () => {
    if (defaultSetting) {
      setFirstReminder(null);
      setSecondReminder(null);
      setDefaultSetting(false);
    } else {
      setDefaultSetting(true);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/invoices">{t("invoices")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Create")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Edit invoice")}</h4>
          <p className="mb-0">{t("Create an invoice for your customer")}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Messages />
        </div>
      </div>
      <Formik
        const
        initialValues={{
          customer_id: customerId,
          contact_person_id: contactPersonId,
          invoice_date: invoiceDate,
          template_id: templateId,
          description: invoiceDescription,
          invoice_lines: invoiceLines,
          email_to: sendInvoiceEmail,
          status
        }}
        validationSchema={editInvoiceSchemaNew}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            updateInvoice(values);
          }, 1000);
        }}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <InvoiceDetails />
            <InvoiceForm />

            <PriceCard onClick={() => handleShowInvoiceLayout()} />

            {remindersEnabled && (
              <InvoiceReminder
                setFirstReminder={setFirstReminder}
                defaultSetting={defaultSetting}
                disableReminderButton={disableReminderButton}
                firstReminder={firstReminder}
                handleDefaultSetting={handleDefaultSetting}
                secondReminder={secondReminder}
                setSecondReminder={setSecondReminder}
              />)}

            <StatusCard
              // publishChecked={publishInvoiceChecked}
              // handlePublish={handlePublicInvoice}
              // isChecked={isChecked}
              // handleSendEmail={handleSendEmail}
              text={t("Publish invoice")}
            />

            <InvoiceLayout
              show={showInvoiceLayout}
              handleClose={handleCloseInvoiceLayout}
              invoiceData={{
                invoice_date: invoiceDate,
                invoice_number: invoicePrefix + invoiceNumber,
              }}
              invoiceLines={values.invoice_lines}
            />

          </Form>
        )}
      </Formik>
    </>
  );
}

export default EditInvoice;
