import React, { useEffect, useState } from "react";
import { Table, Card, Button, Form, Breadcrumb } from "@themesberg/react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Customer from "../lib/Customer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactPersonModal from "./ContactPersonModal";
import Contract from "../../contracts/libs/Contract";
import moment from 'moment-timezone';
import { faEdit, faTrashAlt, faPlus, faEye, faHome } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import AlertDismissible from "../../components/old/AlertDismissible";
import Messages from "../../components/Messages";

function CustomerDetails() {
  const { id } = useParams();
  const { t } = useTranslation();

  const [customer, setCustomer] = useState("");
  const [contactPersons, setContactPersons] = useState([]);
  const [showContactPersonModal, setShowContactPersonModal] = useState(false);
  // const [action, setAction] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contracts, setContracts] = useState([]);
  const [message, setMessage] = useState({});

  const handleCloseContactPerson = () => setShowContactPersonModal(false);
  const handleShowContactPerson = (type, contactPersonUpdate) => {
    if (contactPersonUpdate) {
      setContactPerson(contactPersonUpdate);
    }
    setShowContactPersonModal(true);
    // setAction(type);
  };

  useEffect(() => {
    Customer.getCustomer(id)
      .then(res => {
        if (res.customer) {
          setCustomer(res.customer);
        }
      }).catch(error => console.log(error));
    Contract.getContracts()
      .then(res => {
        setContracts(res.contracts);
      }).catch(error => console.log(error));

    handleContactPersonSelected(id);
    //eslint-disable-next-line
  }, []);

  const handleContactPersonSelected = (customerId) => {
    Customer.fetchCustomerContactPersons(customerId)
      .then(res => {
        setContactPersons(res.contact_persons);
      }).catch(error => console.log(error));
  }

  const deleteContactPerson = (contactPersonId) => {
    Customer.deleteCustomerContactPerson(id, contactPersonId).then((res) => {
      if (res.success === true) {
        setMessage({ show: true, message: res.message, variant: "success" });
        handleContactPersonSelected(id);
      } else if (res.success === false) {
        setMessage({ show: true, message: res.message, variant: "danger" });
      }

    });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item href="/customers">{t("customers")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Details")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Customer details")}</h4>
          <p
            className="mb-0">{t("Showing all contact person and contracts of a customer including customer general info")}</p>
        </div>
      </div>
      <Messages />
      <Card>
        <Card.Header>
          <div className="row">
            <div className="col-md-8">
              <h5>{t("Customer details")}</h5>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table bordered hover responsive="sm">
            <thead>
              <tr>
                <th>{t("Company Name")}</th>
                <td>{customer.company_name}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{t("Street")}</th>
                <td>{customer.street}</td>
              </tr>
              <tr>
                <th>{t("House Number")}</th>
                <td>{customer.house_number}</td>
              </tr>
              <tr>
                <th>{t("House Number Suffix")}</th>
                <td>{customer.house_number_suffix}</td>
              </tr>
              <tr>
                <th>Postcode</th>
                <td>{customer.postcode}</td>
              </tr>
              <tr>
                <th>{t("City")}</th>
                <td>{customer.city}</td>
              </tr>
              <tr>
                <th>{t("Country")}</th>
                <td>{customer.country}</td>
              </tr>
              <tr>
                <th>{t("Phone Number")}</th>
                <td>{customer.phone_number}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Header>
          {message.show ?
            <AlertDismissible type="message" textMessage={message.message} variant={message.variant} /> : ""}
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <h5>{t("Contact Person List")}</h5>
              <div>
                <Button
                  variant="outline-primary" size="sm"
                  onClick={() => {
                    handleShowContactPerson("create");
                    setContactPerson("");
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} /> {t("new")}
                </Button>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table hover responsive="sm">
            <thead>
              <tr>
                <th>{t("First Name")}</th>
                <th>{t("Prefix")}</th>
                <th>{t("Last Name")}</th>
                <th>{t("Email")}</th>
                <th>{t("Phone Number")}</th>
                <th>{t("action")}</th>
              </tr>
            </thead>
            <tbody>
              {
                contactPersons && contactPersons.map(person => {
                  return (
                    <tr key={person.id}>
                      <td>{person.first_name}</td>
                      <td>{person.prefix}</td>
                      <td>{person.last_name}</td>
                      <td>{person.email}</td>
                      <td>{person.phone_number}</td>
                      <td>
                        <Form.Group>
                          <Button
                            variant="outline-success"
                            className="mx-1"
                            size="sm"
                            onClick={(e) => {
                              handleShowContactPerson("edit", person);
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <Button
                            variant="outline-danger"
                            className="mx-1"
                            size="sm"
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  t("delete contact person")
                                )
                              )
                                deleteContactPerson(person.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        </Form.Group>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <ContactPersonModal
        show={showContactPersonModal}
        handleClose={handleCloseContactPerson}
        handleSave={() => handleContactPersonSelected(id)}
        contactPersonData={contactPerson}
        contactPersonId={contactPerson?.id}
        customerId={id}
      />

      <Card className="my-5">
        <Card.Header>
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <h5>{t("Contract List")}</h5>
              <div>
                <Link
                  to="/create-contract"
                  className="btn btn-outline-primary btn-sm"
                >
                  <FontAwesomeIcon icon={faPlus} /> {t("new")}
                </Link>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table hover responsive="sm">
            <thead>
              <tr>
                <th>SL.</th>
                <th>{t("Customer")}</th>
                <th>{t("Email")}</th>
                <th>{t("Start Date")}</th>
                <th>{t("First Inv. Date")}</th>
                <th>{t("Frequency")}</th>
                <th>{t("action")}</th>
              </tr>
            </thead>

            <tbody>
              {contracts && contracts.map((contract, index) => {
                return (
                  <tr key={contract.id}>
                    <td>{index + 1}</td>
                    <td>{contract.customer.company_name}</td>
                    <td>{contract.email}</td>
                    <td>{moment.tz(contract.start_date, 'Europe/Amsterdam').format('DD-MM-YYYY')}</td>
                    <td>{moment.tz(contract.first_invoice_date, 'Europe/Amsterdam').format('DD-MM-YYYY')}</td>
                    <td>{contract.frequency}</td>
                    <td>
                      <Form.Group>
                        <Link to={`/contract-details/${contract.id}`} className="btn btn-outline-info btn-sm ml-1">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </Form.Group>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}

export default CustomerDetails;
