import {
  GET_CUSTOMERS_START,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILED,
  GET_CUSTOMER_BY_ID_START,
  GET_CUSTOMER_BY_ID_SUCCESS,
  GET_CUSTOMER_BY_ID_FAILED,
} from "../actions/actionTypes";

const initialState = {
  // customers: { customers: [], success: false, isLoading: false, error: "" },
  // customer: { customer: [], success: false, isLoading: false, error: "" },
  customers: [],
  customer: {}
};

const customers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_START:
      return {
        ...state,
        isLoading: true,
        customers: [],
      };

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        isLoading: false,
        success: true,
      };

    case GET_CUSTOMERS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GET_CUSTOMER_BY_ID_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CUSTOMER_BY_ID_SUCCESS:
      return {
        ...state,
        customer: action.payload,
        isLoading: false,
        success: true,
      };

    case GET_CUSTOMER_BY_ID_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};

export const selectCustomers = state => state.customers.customers;
export const selectCustomersWithLabelAndValue = state => getCustomersWithLabelAndValue(state);
export const selectCustomer = state => state.customers.customer;

const getCustomersWithLabelAndValue = (state) => selectCustomers(state)?.map((customer) => ({
  label: customer.company_name,
  value: customer.id,
}));

export default customers;
