import React, { useState, useEffect } from "react";
import { Table, Button, Card, Dropdown, ButtonGroup, Breadcrumb } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Contract from "../libs/Contract";
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import { faPlus, faTrashAlt, faEdit, faEllipsisH, faEye, faHome } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import AlertDismissible from "../../components/old/AlertDismissible";
import CustomSpinner from "../../components/CustomSpinner";
import { OldPagination } from "../../components/old/OldPagination";
import { useSelector } from "react-redux";
import { selectMessages } from "../../store/reducers/message";
import Messages from "../../components/Messages";

function Contracts() {
  const { t } = useTranslation();

  const [contracts, setContracts] = useState([]);
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(true);
  const [paginationData, setPaginationData] = useState({
    links: [],
    prevPageUrl: "",
    nextPageUrl: "",
    showingPerPage: "",
    total: ""
  });

  console.log(useSelector(selectMessages));
  console.log('useSelector(selectMessages)');

  // const message

  useEffect(() => {
    getPaginatedContracts();
  }, []);

  const getPaginatedContracts = (page = 0) => {
    setLoading(true);
    Contract.getPaginatedContracts(page)
      .then(res => {
        setLoading(false);
        if (res.contracts) {
          setContracts(res.contracts.data);
          setPaginationData({
            links: res.contracts.links,
            prevPageUrl: res.contracts.prev_page_url,
            nextPageUrl: res.contracts.next_page_url,
            showingPerPage: res.contracts.to,
            total: res.contracts.total
          }
          );
        } else {
          setMessage({ show: true, message: res.message });
        }
      }).catch((error) => {
        setLoading(false);
      })
  }

  const deleteContract = (contractId) => {
    Contract.deleteContract(contractId)
      .then(res => {
        if (res.success === true) {
          setMessage({ show: true, message: res.message, variant: "success" });
          getPaginatedContracts();
        }

      }).catch(error => console.log(error));
  }

  const TableRow = (props) => {
    return (
      <tr>
        <td>
          <span className="fw-normal">
            {props.index + 1}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {props.customer.company_name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {props.email}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {moment.tz(props.start_date, "Europe/Amsterdam").format("DD-MM-YYYY")}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {moment.tz(props.first_invoice_date, "Europe/Amsterdam").format("DD-MM-YYYY")}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {props.frequency}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" drop="down" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown">
              <Dropdown.Item href={`/contract-details/${props.id}`}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> {t("View Details")}
              </Dropdown.Item>
              <Dropdown.Item href={`/edit-contract/${props.id}`}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> {t("edit")}
              </Dropdown.Item>
              <Dropdown.Item className="text-danger"
                onClick={(e) => {
                  if (window.confirm("Are you sure you wish to delete this contract?"))
                    deleteContract(props.id);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> {t("remove")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>{t("contracts")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("contracts")}</h4>
          <p className="mb-0">{t("Showing contracts of your company")}</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Link to="/create-contract" className="btn btn-outline-primary btn-sm"><FontAwesomeIcon icon={faPlus}
              className="me-2" /> {t("new")}
            </Link>
          </ButtonGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <Messages />
            </div>
          </div>
        </div>
      </div>

      {loading ? <CustomSpinner /> : <Card border="light" className="table-wrapper table-responsive shadow-sm">
        {message.show ? <AlertDismissible textMessage={message.message} variant={message.variant} /> : ""}
        <Card.Body className="pt-0 pb-5">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">{t("Customer")}</th>
                <th className="border-bottom">{t("Email")}</th>
                <th className="border-bottom">{t("Start Date")}</th>
                <th className="border-bottom">{t("First Invoice Date")}</th>
                <th className="border-bottom">{t("Frequency")}</th>
                <th className="border-bottom">{t("action")}</th>
              </tr>
            </thead>
            <tbody>
              {contracts && contracts.map((contract, index) => <TableRow key={`contract-${index}`}
                index={index} {...contract} />)}
            </tbody>
          </Table>
          <OldPagination action={Contract} items={contracts} paginationData={paginationData}
            getPaginated={getPaginatedContracts} />
        </Card.Body>
      </Card>}
    </>
  );
}

export default Contracts;
