import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "@themesberg/react-bootstrap";
import Company from "../../companies/libs/Company";
import Toggle from "../../settings/components/ToggleSwitch/switch";
import { useTranslation } from "react-i18next";
import { FormCheck } from "react-bootstrap";
import AlertDismissible from "../../components/old/AlertDismissible";
import { Field, Formik } from "formik";
import { validationSchema } from "../../validation";
import AutoCompleteCompanyField from "../../components/inputs/AutoCompleteCompanyField";
import { messageShown } from "../../store/reducers/message";
import { useDispatch } from "react-redux";

function CustomerModal({ show, handleClose, customerData, handleSave }) {
  const { t } = useTranslation();

  const [company, setCompany] = useState(null);
  const [defaultSetting, setDefaultSetting] = useState(true);
  const [firstReminder, setFirstReminder] = useState(null);
  const [secondReminder, setSecondReminder] = useState(null);
  const [disableReminderButton, setDisableReminderButton] = useState(true);
  const [message, setMessage] = useState({});
  const [error, setError] = useState([]);

  const dispatch = useDispatch();

  const handleDefaultSetting = () => {
    if (defaultSetting) {
      setFirstReminder(null);
      setSecondReminder(null);
      setDefaultSetting(false);
    } else {
      setDefaultSetting(true);
    }
  };

  useEffect(() => {
    setCompany(customerData?.company_name ? {
      label: customerData?.company_name,
      companyName: customerData?.company_name
    } : null);

    if (customerData) {
      setDefaultSetting(!customerData.reminder_override)
      if (customerData.reminder_override) {
        setFirstReminder(customerData.first_reminder)
        if (customerData.first_reminder) {
          setSecondReminder(customerData.second_reminder)
        }
      }
    }

  }, [show, customerData]);

  useEffect(() => {
    if (defaultSetting) {
      setFirstReminder(null);
      setSecondReminder(null);
      setDisableReminderButton(true);
    } else {
      if (firstReminder) {
        setDefaultSetting(false)
        setDisableReminderButton(false);
      } else {
        setSecondReminder(null);
        setDisableReminderButton(true);
      }
    }
  }, [defaultSetting, firstReminder, secondReminder]);

  const handleCompanyUpdated = (newCompany, values, setFieldValue) => {
    setCompany(newCompany)
    if (newCompany?.companyName) {
      setFieldValue('company_name', newCompany.companyName);
    }

    if (newCompany.phone && !values.phone_number) {
      setFieldValue("phone_number", newCompany.phone);
    }

    if (newCompany.street && !values.street) {
      setFieldValue("street", newCompany.street);
    }

    if (newCompany.btwnumber && !values.vat_number) {
      setFieldValue("vat_number", newCompany.btwnumber);
    }

    if (newCompany.kvk_number && !values.coc) {
      setFieldValue("coc", newCompany.kvk_number);
    }

    if (newCompany.street_number && !values.house_number) {
      setFieldValue("house_number", newCompany.street_number);
    }

    if (newCompany.street_number_suffix && !values.suffix) {
      setFieldValue("suffix", newCompany.street_number_suffix);
    }

    if (newCompany.postcode && !values.postcode) {
      setFieldValue("postcode", newCompany.postcode);
    }

    if (newCompany.city && !values.city) {
      setFieldValue("city", newCompany.city);
    }
  }

  const storeCustomer = (values) => {

    let reminderOverride = !defaultSetting && Boolean(firstReminder);

    values = {
      ...values,
      reminder_override: reminderOverride,
      first_reminder: !reminderOverride ? null : firstReminder,
      second_reminder: !reminderOverride ? null : secondReminder,
    }

    const handleSuccessResponse = (res) => {
      if (res.success) {
        dispatch(messageShown({
          message: res.message,
          variant: "success",
        }));

        if (handleSave) {
          handleSave(res.customer);
        }
      } else {
        setMessage({ message: res.message, variant: "warning" });
      }

      if (res.errors) {
        setError(res.errors?.map((error) => ({
          message: error?.[0],
          variant: "danger",
        })));
      }
    };

    if (values.id) {
      Company.updateCompanyCustomer(values).then(handleSuccessResponse);
    } else {
      Company.createCompanyCustomer(values).then(handleSuccessResponse);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Formik
          initialValues={{
            id: customerData?.id,
            company_name: customerData?.company_name,
            phone_number: customerData?.phone_number,
            street: customerData?.street,
            house_number: customerData?.house_number,
            house_number_suffix: customerData?.house_number_suffix,
            postcode: customerData?.postcode,
            city: customerData?.city,
            country: customerData?.country || "NL",
            vat_number: customerData?.vat_number,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              storeCustomer(values);
            }, 1000);
          }}
          validationSchema={validationSchema}
        >
          {({
            setFieldValue,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {values?.id ? t("Edit Customer") : t("New Customer")}
                </Modal.Title>
              </Modal.Header>
              {message.show ? (
                <AlertDismissible
                  textMessage={message.message}
                  variant={message.variant}
                />
              ) : (
                ""
              )}
              {error &&
                error.map((message, index) => {
                  return (
                    <AlertDismissible
                      key={index}
                      textMessage={message?.message}
                      variant="danger"
                    />
                  );
                })}
              <Modal.Body>
                <div className="row">
                  <Field type="hidden" name="id" />
                  <Field type="hidden" name="company_name" />
                  <Form.Group className="col-md-6">
                    <Form.Label>{t("Company")}</Form.Label>
                    <AutoCompleteCompanyField
                      withData={true}
                      value={company}
                      onChange={(newCompany) => handleCompanyUpdated(newCompany, values, setFieldValue)}
                    />
                    {errors.company?.companyName && touched.company && (
                      <span className="error-message">
                        {t(errors.company.companyName)}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>{t("Phone Number")}</Form.Label>
                    <Field
                      as={Form.Control}
                      name="phone_number"
                      type="text"
                    />
                    {errors.phone_number && touched.phone_number && (
                      <span className="error-message">
                        {t(errors.phone_number)}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <div className="row mt-2">
                  <Form.Group className="col-md-6">
                    <Form.Label>{t("Street")}</Form.Label>
                    <Field
                      as={Form.Control}
                      name="street"
                      type="text"
                    />
                    {errors.street && touched.street && (
                      <span className="error-message">{t(errors.street)}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="col-md-3">
                    <Form.Label>{t("House Number")}</Form.Label>
                    <Field
                      as={Form.Control}
                      name="house_number"
                      type="text"
                    />
                    {errors.house_number && touched.house_number ? (
                      <span className="error-message">
                        {t(errors.house_number)}
                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="col-md-3">
                    <Form.Label>{t("Suffix")}</Form.Label>
                    <Field
                      as={Form.Control}
                      name="suffix"
                      type="text"
                    />
                  </Form.Group>
                </div>
                <div className="row mt-2">
                  <Form.Group className="col-md-6">
                    <Form.Label>{t("City")}</Form.Label>
                    <Field
                      as={Form.Control}
                      name="city"
                      type="text"
                    />
                    {errors.city && touched.city ? (
                      <span className="error-message">{t(errors.city)}</span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>Postcode</Form.Label>
                    <Field
                      as={Form.Control}
                      name="postcode"
                      type="text"
                    />
                    {errors.postcode && touched.postcode ? (
                      <span className="error-message">
                        {t(errors.postcode)}
                      </span>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="row mt-2">
                  <Form.Group className="col-md-6">
                    <Form.Label>{t("Country")}</Form.Label>
                    <select
                      className="form-control user-select"
                      onChange={(e) => setFieldValue('country', e.target.value)}
                      value={values.country}
                    >
                      <option value="NL">
                        {t("Netherlands")}
                      </option>
                    </select>
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>{t("VAT Number")}</Form.Label>
                    <Field
                      as={Form.Control}
                      name="vat_number"
                      type="text"
                    />
                  </Form.Group>
                </div>
                <div className="row  mt-2">
                  <div className="col-12">
                    <Form.Check type="checkbox">
                      <FormCheck.Input
                        id="defaultCheck5"
                        className="me-2"
                        checked={defaultSetting}
                        onChange={handleDefaultSetting}
                      />
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">
                        {t("Use the default settings for invoice reminders for this customer")}
                      </FormCheck.Label>
                    </Form.Check>
                  </div>
                </div>
                {!defaultSetting && (
                  <div className="row">
                    <div className="col-4">
                      <Form.Group className="d-flex pt-4">
                        <div className="px-2">
                          <Toggle
                            checked={firstReminder}
                            handleChange={(checked) => setFirstReminder(checked)}
                          />
                        </div>
                        <Form.Label>{t("Reminder")}</Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group className="d-flex pt-4">
                        <div className="px-2">
                          <Toggle
                            checked={secondReminder}
                            disabled={disableReminderButton}
                            handleChange={(checked) => setSecondReminder(checked)}
                          />
                        </div>
                        <Form.Label>{t("Second Reminder")}</Form.Label>
                      </Form.Group>
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={handleClose}>
                  {t("Close")}
                </Button>
                <Button
                  variant="primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {values?.id ? t("Edit Customer") : t("Create new")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default CustomerModal;
