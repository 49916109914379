import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Form, InputGroup} from "@themesberg/react-bootstrap";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import ContactPersonModal from "../../customers/components/ContactPersonModal";
import CustomerModal from "../../customers/components/CustomerModal";
import Customer from "../../customers/lib/Customer";
import CreateTemplateModal from "../../settings/components/Template/CreateTemplate";
import {fetchCompanyCustomers} from "../../store/actions/customers";
import {Field, useFormikContext} from "formik";
import {selectCustomers} from "../../store/reducers/customers";
import {selectTemplates} from "../../store/reducers/templates";
import {fetchTemplates} from "../../store/actions/templates";

function InvoiceDetails() {
  const {t} = useTranslation();

  const {setFieldValue, errors, touched, values} = useFormikContext();

  const [customerOptions, setCustomerOptions] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [contactPersonOptions, setContactPersonOptions] = useState([]);
  const [templateModal, setTemplateModal] = useState(false);
  const [showCustomerModal, setShowCustomer] = useState(false);
  const handleCloseCustomer = () => setShowCustomer(false);
  const [showContactPersonModal, setShowContactPersonModal] = useState(false);
  const handleCloseContactPerson = () => setShowContactPersonModal(false);

  const customerList = useSelector(selectCustomers);
  const templatesList = useSelector(selectTemplates);
  const [defaultTemplateOption, setDefaultTemplateOption] = useState({}); 

  const dispatch = useDispatch();

  const handleNewCustomer = (customer) => {
    dispatch(fetchCompanyCustomers());
    setFieldValue('customer_id', customer?.id)
    setShowCustomer(false)
  };

  const handleNewContactPerson = (contactPerson) => {
    fetchCustomerContactPersons(values.customer_id).then(() => {
      setFieldValue('contact_person_id', contactPerson.id)
    });
  }

  const handleTemplateCreated = (template) => {
    setFieldValue('contact_person_id', template?.id)
  }

  const fetchCustomerContactPersons = async (customerId) => {
    try {
      const res = await Customer.fetchCustomerContactPersons(customerId);
      setContactPersonOptions(res.contact_persons.map(contactPerson => {
        const prefix = contactPerson.prefix ? contactPerson.prefix + " " : "";

        return {
          label: `${prefix}${contactPerson.first_name} ${contactPerson.last_name} (${contactPerson.email})`,
          value: contactPerson.id,
          ...contactPerson
        };
      }));
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (values.contact_person_id) {
      const contactPerson = contactPersonOptions.filter(contactPersonOption => contactPersonOption.id === values.contact_person_id)?.[0];
      if (!contactPerson) {
        setFieldValue('contact_person_id', null);
      }
    }
  }, [contactPersonOptions]);

  useEffect(() => {
    dispatch(fetchTemplates());
    // eslint-disable-next-line
  }, [values.template_id]);

  useEffect(() => {
    dispatch(fetchCompanyCustomers());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCustomerOptions(customerList?.map((customer) => ({
      label: customer.company_name,
      value: customer.id,
    })));
  }, [customerList]);

  useEffect(() => {
    if (values.customer_id) {
      fetchCustomerContactPersons(values.customer_id)
    }
    // eslint-disable-next-line
  }, [values.customer_id]);

  useEffect(() => {
    const options = templatesList?.map((template) => {
      if (template.is_default) {
        setDefaultTemplateOption({ label: template.name, value: template.id });
      }
      return { label: template.name, value: template.id };
    });
  
    setTemplateOptions(options);

    // eslint-disable-next-line
  }, [templatesList]);

  useEffect(() => {
    const filteredContactPerson = contactPersonOptions.filter((contactPerson) => {
      return values.contact_person_id === contactPerson.id;
    })?.[0];

    if (filteredContactPerson?.email) {
      setFieldValue("email_to", filteredContactPerson?.email);
    }
    // eslint-disable-next-line
  }, [contactPersonOptions, values.contact_person_id]);

  return (
    <>
      <Card>
        <Card.Header className="p-2">
          <h5>{t("Invoice Info")}</h5>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <Form.Group className="col-md-6">
              <Form.Label>{t("Select customer")} </Form.Label>
              <div className="d-flex flex-column">
                <InputGroup>
                  <Select
                    name="customer_id"
                    placeholder={t("select")}
                    className="form-control p-0"
                    onChange={(customerObject) => setFieldValue("customer_id", customerObject.value)}
                    options={customerOptions}
                    value={
                      customerOptions?.filter(
                        (customer) => customer.value === values.customer_id
                      )?.[0] || null
                    }
                  />
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="d-inline"
                    onClick={() => setShowCustomer(true)}
                  >
                    <FontAwesomeIcon icon={faPlus}/>
                  </Button>
                </InputGroup>
                {errors.customer_id && touched.customer_id ? (
                  <div className="validation-error">
                    {t(errors.customer_id)}
                  </div>
                ) : null}
              </div>
            </Form.Group>

            <Form.Group className="col-md-6">
              <Form.Label>{t("Select Contact Person")}</Form.Label>
              <InputGroup>
                <Select
                  name="contact_person_id"
                  placeholder={t("select")}
                  className="form-control p-0"
                  onChange={(contactPerson) => {
                    setFieldValue("contact_person_id", contactPerson.value);
                  }}
                  value={
                    contactPersonOptions?.filter(
                      (contactPerson) =>
                        values.contact_person_id === contactPerson.value
                    )?.[0] || null
                  }
                  isDisabled={!values.customer_id}
                  options={contactPersonOptions}
                />
                {values.customer_id && (
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="d-inline"
                    onClick={() => setShowContactPersonModal(true)}
                  >
                    <FontAwesomeIcon icon={faPlus}/>
                  </Button>
                )}
              </InputGroup>
              {errors.contact_person_id && touched.contact_person_id ? (
                <div className="validation-error">
                  {errors.contact_person_id}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className="col-md-6 mt-2 d-flex flex-column">
              <Form.Label>{t("Invoice date")}</Form.Label>
              <DatePicker
                name="invoice_date"
                className="form-control"
                dateFormat="dd-MM-yyyy"
                placeholderText="Choose a date"
                selected={values.invoice_date}
                calendarStartDay={1}
                value={values.invoice_date}
                onChange={(date) => setFieldValue("invoice_date", date)}
              />
              {errors.invoice_date && touched.invoice_date ? (
                <div className="validation-error">
                  {t(errors.invoice_date)}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 mt-2">
              <Form.Label>{t("Select Template")}</Form.Label>
              <InputGroup>
                <Select
                  name="template_id"
                  placeholder={t("select")}
                  className="form-control p-0"
                  onChange={(templateObject) => setFieldValue("template_id", templateObject.value)}
                  value={
                    templateOptions?.filter(
                      (template) => template.value === values.template_id
                    )?.[0] || defaultTemplateOption
                  }
                  options={templateOptions}
                />
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="d-inline"
                  onClick={() => setTemplateModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus}/>
                </Button>
              </InputGroup>
              {errors.template_id && touched.template_id ? (
                <div className="validation-error">{errors.template_id}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 mt-2">
              <Form.Label>{t("Invoice Description")}</Form.Label>
              <div className="d-flex flex-column">
                <Field
                  as={Form.Control}
                  name="description"
                ></Field>
              </div>
              {errors.description && touched.description ? (
                <div className="validation-error">{t(errors.description)}</div>
              ) : null}
            </Form.Group>
          </div>
        </Card.Body>
      </Card>

      <CustomerModal
        show={showCustomerModal}
        handleClose={handleCloseCustomer}
        handleSave={handleNewCustomer}
      />

      <ContactPersonModal
        show={showContactPersonModal}
        handleClose={handleCloseContactPerson}
        customerId={values.customer_id}
        handleSave={handleNewContactPerson}
      />

      <CreateTemplateModal
        show={templateModal}
        templateId={values.template_id}
        handleClose={() => setTemplateModal(false)}
        handleSave={handleTemplateCreated}
        setRefreshData={() => {
          return;
        }}
      />
    </>
  );
}

export default InvoiceDetails;
